import React, { useState } from "react";
import { CheckoutArrowIcon } from "../../../assets/icons/CheckoutArrowIcon.jsx";

const NovaPoshtaInput = ({ city, setCity, branch, setBranch }) => {
  const [selectedCity, setSelectedCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [citySelectOpened, setCitySelectOpened] = useState(false);
  const [branchSelectOpened, setBranchSelectOpened] = useState(false);

  const fetchCities = async (searchQuery) => {
    const response = await fetch("https://api.novaposhta.ua/v2.0/json/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        apiKey: "cb89ddb892d164ed76f01e2b11f862e3",
        modelName: "Address",
        calledMethod: "getCities",
        methodProperties: {
          FindByString: searchQuery,
        },
      }),
    });

    const data = await response.json();
    setCities(data.data);
  };

  const fetchBranches = async (cityRef) => {
    const response = await fetch("https://api.novaposhta.ua/v2.0/json/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        apiKey: "cb89ddb892d164ed76f01e2b11f862e3",
        modelName: "Address",
        calledMethod: "getWarehouses",
        methodProperties: {
          CityRef: cityRef,
        },
      }),
    });

    const data = await response.json();
    setBranches(data.data);
  };
  const handleCityChange = (e) => {
    setBranch("");
    setCity(e.target.value);
    setSelectedCity(null);
    setBranches([]);
    setFilteredBranches([]);
    if (e.target.value.length > 2) {
      fetchCities(e.target.value);
    } else {
      setCities([]);
    }
  };

  const handleCitySelect = (city) => {
    setCity(city.Description);
    setSelectedCity(city);
    setCities([]);
    fetchBranches(city.Ref);
  };

  const handleBranchChange = (e) => {
    const inputValue = e.target.value;
    setBranch(inputValue);

    const filtered = branches.filter((branch) =>
      branch.Description.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredBranches(filtered);
  };

  const handleBranchSelect = (branch) => {
    setBranch(branch.Description);
    setFilteredBranches([]);
  };

  return (
    <div className="novaPoshta__thumb">
      <div className="novaPoshta__method--wrapper">
        <div className="novaPoshta__method--choose">
          <div className="novaPoshta__method--choosen"></div>
        </div>
        <p className="novaPoshta__option">Самовивіз з Нової Пошти</p>
      </div>
      <div className="novaPoshta__delivery--wrapper">
        <div className="novaPoshta__delivery--thumb">
          <p className="novaPoshta__label">Місто</p>
          <div
            className="novaPoshta__select"
            onClick={() => {
              setCitySelectOpened((prev) => !prev);
            }}
          >
            <p className="novaPoshta__select--placeholder">
              {city ? city : "Оберіть місто"}
            </p>
            <CheckoutArrowIcon />
          </div>
          {citySelectOpened && (
            <div className="novaPoshta__modal--wrapper">
              <input
                type="text"
                value={city}
                onChange={handleCityChange}
                placeholder="Введіть назву міста або населеного пункту"
                className="novaPoshta__modal--input"
                style={cities.length > 0 ? { marginBottom: "8px" } : {}}
              />
              {cities.length > 0 && (
                <ul className="novaPoshta__modal--list">
                  {cities.map((city) => (
                    <li
                      key={city.Ref}
                      onClick={() => {
                        handleCitySelect(city);
                        setCitySelectOpened(false);
                      }}
                      className="novaPoshta__modal--item"
                    >
                      {city.Description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
        <div className="novaPoshta__delivery--thumb">
          <p className="novaPoshta__label">Відділення </p>
          <div
            className="novaPoshta__select"
            onClick={() => {
              setBranchSelectOpened((prev) => !prev);
            }}
          >
            <p className="novaPoshta__select--placeholder">
              {branch ? branch : "Оберіть відділеня"}
            </p>
            <CheckoutArrowIcon />
          </div>
          {branchSelectOpened && (
            <div className="novaPoshta__modal--wrapper">
              <input
                type="text"
                value={branch}
                onChange={handleBranchChange}
                placeholder="Введіть адресу або номер відділення"
                className="novaPoshta__modal--input"
                style={cities.length > 0 ? { marginBottom: "8px" } : {}}
              />
              {filteredBranches.length > 0 && (
                <ul className="novaPoshta__modal--list">
                  {filteredBranches.map((branch) => (
                    <li
                      key={branch.Ref}
                      onClick={() => {
                        handleBranchSelect(branch);
                        setBranchSelectOpened(false);
                      }}
                      className="novaPoshta__modal--item"
                    >
                      {branch.Description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NovaPoshtaInput;
