import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  getAllReviews,
  getCurrentReview, hideReview,
  tryLogin,
} from "../../services/axios.js";
import { ReviewCreate } from "../../components/ReviewCreate/ReviewCreate.jsx";
import { ReviewEdit } from "../../components/ReviewEdit/ReviewEdit.jsx";

export const ReviewsPanel = () => {
  const [page, setPage] = useState("base");
  const [reviews, setReviews] = useState([]);
  const [review, setReview] = useState(null);

  useEffect(() => {
    const tryGetAccess = async () => {
      const password = localStorage.getItem("passwordToAdminPanel");
      if (password) {
        if ((await tryLogin(password)) === "denied") {
          window.location.href = "/";
        }
      } else {
        window.location.href = "/";
      }
    };

    tryGetAccess();
  }, []);

  const notify = () => toast.success("Success!");

  useEffect(() => {
    const fetchNewSections = async () => {
      const fetchedReviews = await getAllReviews();
      setReviews(fetchedReviews);
    };
    fetchNewSections();
  }, []);

  return (
    <div className="container" style={{ minHeight: "100vh", padding: "0" }}>
      {page === "base" ? (
        <>
          <button
            type="button"
            className="model__panel--button"
            onClick={() => {
              setPage("create");
            }}
          >
            Створити
          </button>
          <div className="panel__list">
            {reviews?.map(({ _id, id, name, hidden }) => (
                <div className="panel__item--wrapper" key={_id}>
                  <div
                      style={
                        !hidden
                            ? {backgroundColor: "#fcb25f"}
                            : {
                              backgroundColor: "rgb(189, 193, 198)",
                              border: "rgb(93, 93, 93)",
                            }
                      }
                      key={_id}
                      className="panel__item"
                      onClick={async () => {
                        setReview(await getCurrentReview(_id));
                        setPage("edit");
                      }}
                  >
                    <div className="panel__id--wrapper">
                      <p className="panel__id">{id}</p>
                    </div>
                    <p className="panel__name">{name}</p>
                  </div>
                  <button
                      className="panel__button--hide"
                      onClick={() => {
                        hideReview(_id, !hidden);
                        notify();
                      }}
                  >
                    {!hidden ? "Active" : "Hidden"}
                  </button>
                </div>
            ))}
          </div>
        </>
      ) : page === "create" ? (
          <ReviewCreate/>
      ) : (
          <ReviewEdit review={review}/>
      )}
      <ToastContainer/>
    </div>
  );
};
