import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {updateFastfilter} from "../../services/axios.js";

export const EditFastFilter = ({fastFilter}) => {
    const defaultValues = {
        id: fastFilter?.id,
        title: fastFilter?.title,
        order: `${fastFilter?.order || 0}`,
        relatedTo: fastFilter?.relatedTo
    };
    const { register, handleSubmit } = useForm({ defaultValues });
    const [modelsIds, setModelsIds] = useState(fastFilter.models || [""]);

    const notify = () => toast.success("Success!");


    const onModelIdChange = (event, index) => {
        setModelsIds((prevState) => {
            const array = [...prevState];
            array[index] = event.target.value;
            return array;
        });
    };
    const onAddModelClick = () => {
        setModelsIds((prevState) => [...prevState, ""]);
    };

    const onFormSubmit = (data) => {
        const {
            id,
            title,
            relatedTo
        } = data;
        const fastFilterData = {
            id,
            title,
            relatedTo,
            order: +data?.order,
            models: modelsIds.filter((el) => el.trim() !== ""),
        };
        updateFastfilter(fastFilter?._id, fastFilterData);
        notify();
    };

    return (
        <form onSubmit={handleSubmit(onFormSubmit)} className="panel__form">
            <div className="panel__input--wrapper">
                <input
                    type="text"
                    className="model__panel--input"
                    {...register("id", {required: true})}
                    placeholder="ID"
                />
                <input
                    type="text"
                    className="model__panel--input"
                    {...register("title", {required: true})}
                    placeholder="Title"
                />
                <input
                    type="text"
                    className="model__panel--input"
                    {...register("order", {required: true})}
                    placeholder="Order"
                />
                <input
                    type="text"
                    className="model__panel--input"
                    {...register("relatedTo", {required: true})}
                    placeholder="Related To"
                />
                {modelsIds &&
                    modelsIds.map((el, index) => (
                        <input
                            type="text"
                            key={index}
                            className="model__panel--input"
                            placeholder={`model ${index + 1}`}
                            value={modelsIds[index]}
                            onChange={(e) => onModelIdChange(e, index)}
                        />
                    ))}
                <button
                    type={"button"}
                    className="model__panel--input"
                    onClick={onAddModelClick}
                >
                    Додати модель
                </button>
            </div>

            <button
                type="submit"
                className="model__panel--button"
                style={{margin: "25px auto 0"}}
            >
                Save
            </button>
        </form>
    );
};
