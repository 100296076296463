import { useEffect, useState } from "react";

import {
  deletePromocode,
  getAllPromocodes,
  getPromocode,
  tryLogin,
} from "../../services/axios";
import { ToastContainer, toast } from "react-toastify";
import { PromocodeCreate } from "../../components/PromocodeCreate/PromocodeCreate";
import { PromocodeEdit } from "../../components/PromocodeEdit/PromocodeEdit";

export const PromocodePanel = () => {
  const [page, setPage] = useState("base");
  const [promocodes, setPromocodes] = useState([]);
  const [item, setItem] = useState(null);

  useEffect(() => {
    const tryGetAccess = async () => {
      const password = localStorage.getItem("passwordToAdminPanel");
      if (password) {
        if ((await tryLogin(password)) === "denied") {
          window.location.href = "/";
        }
      } else {
        window.location.href = "/";
      }
    };

    tryGetAccess();
  }, []);

  const notify = () => toast.success("Success!");

  useEffect(() => {
    const fetchPromocodes = async () => {
      const fetchedPromocodes = await getAllPromocodes();

      setPromocodes(fetchedPromocodes);
    };
    fetchPromocodes();
  }, []);

  return (
    <div className="container" style={{ minHeight: "100vh", padding: "0" }}>
      {page === "base" ? (
        <>
          <button
            type="button"
            className="model__panel--button"
            onClick={() => {
              setPage("create");
            }}
          >
            Створити
          </button>
          <div className="panel__list">
            {promocodes?.map(
              ({ _id, promocode, type, price, discount }, index) => (
                <div className="panel__item--wrapper" key={_id}>
                  <div
                    style={{ backgroundColor: "#fcb25f" }}
                    key={_id}
                    className="panel__item"
                    onClick={async () => {
                      setItem(await getPromocode(promocode));
                      setPage("edit");
                    }}
                  >
                    <div className="panel__id--wrapper">
                      <p className="panel__id">{index + 1}</p>
                    </div>
                    <p className="panel__name">Promocode: {promocode}</p>
                    <p className="panel__name">Type: {type}</p>
                    <p className="panel__name">Результат: {discount}</p>
                  </div>
                  <button
                    className="panel__button--hide"
                    onClick={() => {
                      deletePromocode(_id);
                      notify();
                    }}
                  >
                    delete
                  </button>
                </div>
              )
            )}
          </div>
        </>
      ) : page === "create" ? (
        <PromocodeCreate />
      ) : (
        <PromocodeEdit item={item} />
      )}
      <ToastContainer />
    </div>
  );
};
