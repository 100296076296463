export const CartBinIcon = ({ handleClick, index, quantity }) => {
  return (
    <svg
      onClick={() => {
        handleClick(index, -quantity);
      }}
      className="cart__model--symbol"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 4.90063H16.5V4.15063C16.5 3.5539 16.2629 2.9816 15.841 2.55964C15.419 2.13769 14.8467 1.90063 14.25 1.90063H9.75C9.15326 1.90063 8.58097 2.13769 8.15901 2.55964C7.73705 2.9816 7.5 3.5539 7.5 4.15063V4.90063H3.75C3.55109 4.90063 3.36032 4.97965 3.21967 5.1203C3.07902 5.26096 3 5.45172 3 5.65063C3 5.84955 3.07902 6.04031 3.21967 6.18096C3.36032 6.32162 3.55109 6.40063 3.75 6.40063H4.5V19.9006C4.5 20.2985 4.65804 20.68 4.93934 20.9613C5.22064 21.2426 5.60218 21.4006 6 21.4006H18C18.3978 21.4006 18.7794 21.2426 19.0607 20.9613C19.342 20.68 19.5 20.2985 19.5 19.9006V6.40063H20.25C20.4489 6.40063 20.6397 6.32162 20.7803 6.18096C20.921 6.04031 21 5.84955 21 5.65063C21 5.45172 20.921 5.26096 20.7803 5.1203C20.6397 4.97965 20.4489 4.90063 20.25 4.90063ZM9 4.15063C9 3.95172 9.07902 3.76096 9.21967 3.6203C9.36032 3.47965 9.55109 3.40063 9.75 3.40063H14.25C14.4489 3.40063 14.6397 3.47965 14.7803 3.6203C14.921 3.76096 15 3.95172 15 4.15063V4.90063H9V4.15063ZM18 19.9006H6V6.40063H18V19.9006ZM10.5 10.1506V16.1506C10.5 16.3495 10.421 16.5403 10.2803 16.681C10.1397 16.8216 9.94891 16.9006 9.75 16.9006C9.55109 16.9006 9.36032 16.8216 9.21967 16.681C9.07902 16.5403 9 16.3495 9 16.1506V10.1506C9 9.95172 9.07902 9.76096 9.21967 9.62031C9.36032 9.47965 9.55109 9.40063 9.75 9.40063C9.94891 9.40063 10.1397 9.47965 10.2803 9.62031C10.421 9.76096 10.5 9.95172 10.5 10.1506ZM15 10.1506V16.1506C15 16.3495 14.921 16.5403 14.7803 16.681C14.6397 16.8216 14.4489 16.9006 14.25 16.9006C14.0511 16.9006 13.8603 16.8216 13.7197 16.681C13.579 16.5403 13.5 16.3495 13.5 16.1506V10.1506C13.5 9.95172 13.579 9.76096 13.7197 9.62031C13.8603 9.47965 14.0511 9.40063 14.25 9.40063C14.4489 9.40063 14.6397 9.47965 14.7803 9.62031C14.921 9.76096 15 9.95172 15 10.1506Z"
        fill="#8C8C8C"
      />
    </svg>
  );
};
