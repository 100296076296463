import { Data } from "./Data/Data";

export const Cart = ({ setCartOpen }) => {
  return (
    <div
      className="present__backdrop"
      id="backdrop"
      onClick={(e) => {
        if (e.target.id === "backdrop") {
          setCartOpen(false);
        }
      }}
    >
      <Data setCartOpen={setCartOpen} />
    </div>
  );
};
