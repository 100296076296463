export const LastSeenItem = ({ model }) => {
  return (
    <div className="lastSeen__item">
      <img
        src={model.image}
        alt={model.name}
        className="lastSeen__item--image"
      />
      <div className="lastSeen__item--wrapper">
        <div className="lastSeen__item--thumb">
          <p className="lastSeen__item--name">{model.name}</p>
          <p className="lastSeen__item--additional">
            {model?.filterTypes} / {model?.filterMaterials}
          </p>
        </div>
        <p className="lastSeen__item--price">{model?.newPrice}</p>
      </div>
    </div>
  );
};
