export const CartArrow = () => {
  return (
    <svg
      className="cart__arrow--icon"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.7081 16.7074L12.7081 26.7074C12.6151 26.8004 12.5048 26.8741 12.3835 26.9243C12.2621 26.9746 12.132 27.0005 12.0006 27.0005C11.8692 27.0005 11.7391 26.9746 11.6177 26.9243C11.4963 26.8741 11.386 26.8004 11.2931 26.7074C11.2001 26.6145 11.1264 26.5042 11.0762 26.3828C11.0259 26.2614 11 26.1313 11 25.9999C11 25.8686 11.0259 25.7384 11.0762 25.6171C11.1264 25.4957 11.2001 25.3854 11.2931 25.2924L20.5868 15.9999L11.2931 6.70745C11.1054 6.5198 11 6.26531 11 5.99995C11 5.73458 11.1054 5.48009 11.2931 5.29245C11.4807 5.1048 11.7352 4.99939 12.0006 4.99939C12.2659 4.99939 12.5204 5.1048 12.7081 5.29245L22.7081 15.2924C22.801 15.3853 22.8748 15.4956 22.9251 15.617C22.9754 15.7384 23.0013 15.8685 23.0013 15.9999C23.0013 16.1314 22.9754 16.2615 22.9251 16.3829C22.8748 16.5043 22.801 16.6146 22.7081 16.7074Z"
        fill="#FCB25F"
      />
    </svg>
  );
};
