import Slider from "react-slick";
import { LastSeenItem } from "../../LastSeenItem/LastSeenItem.jsx";
import { Link } from "react-router-dom";
import { CartArrow } from "../../../assets/icons/CartArrow.jsx";
import { useRef, useState } from "react";

export const LastSeenModels = ({ models, setCartOpen }) => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderOptions = {
    dots: false,
    arrows: true,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const shouldShowRightArrow =
    models.length >= 4 && currentSlide < models.length - 3;
  const shouldShowLeftArrow = currentSlide > 0;

  return (
    <div className="cart__lastSeen--wrapper">
      <p className="cart__lastSeen--text">Останні переглянуті товари</p>
      <div className="cart__line"></div>
      <Slider
        ref={sliderRef}
        {...sliderOptions}
        className="cart__lastSeen--list"
      >
        {models.map((model, index) => (
          <Link
            key={index}
            to={`/modeli/${model.path}`}
            onClick={() => {
              setCartOpen();
            }}
          >
            <LastSeenItem model={model} />
          </Link>
        ))}
      </Slider>
      {shouldShowLeftArrow && (
        <button
          className="cart__left--arrow"
          onClick={() => sliderRef.current.slickPrev()}
        >
          <CartArrow />
        </button>
      )}

      {shouldShowRightArrow && (
        <button
          className="cart__right--arrow"
          onClick={() => sliderRef.current.slickNext()}
        >
          <CartArrow />
        </button>
      )}
    </div>
  );
};
