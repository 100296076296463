export const CartPlusIcon = ({ handleClick, index }) => {
  return (
    <svg
      onClick={() => {
        handleClick(index, 1);
      }}
      className="cart__model--symbol"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 12.4006C21 12.5995 20.921 12.7903 20.7803 12.931C20.6397 13.0716 20.4489 13.1506 20.25 13.1506H12.75V20.6506C12.75 20.8495 12.671 21.0403 12.5303 21.181C12.3897 21.3216 12.1989 21.4006 12 21.4006C11.8011 21.4006 11.6103 21.3216 11.4697 21.181C11.329 21.0403 11.25 20.8495 11.25 20.6506V13.1506H3.75C3.55109 13.1506 3.36032 13.0716 3.21967 12.931C3.07902 12.7903 3 12.5995 3 12.4006C3 12.2017 3.07902 12.011 3.21967 11.8703C3.36032 11.7297 3.55109 11.6506 3.75 11.6506H11.25V4.15063C11.25 3.95172 11.329 3.76096 11.4697 3.6203C11.6103 3.47965 11.8011 3.40063 12 3.40063C12.1989 3.40063 12.3897 3.47965 12.5303 3.6203C12.671 3.76096 12.75 3.95172 12.75 4.15063V11.6506H20.25C20.4489 11.6506 20.6397 11.7297 20.7803 11.8703C20.921 12.011 21 12.2017 21 12.4006Z"
        fill="#FCB25F"
      />
    </svg>
  );
};
