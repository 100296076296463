export const PromocodeApplied = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.7081 10.1082L12.7081 26.1082C12.6152 26.2012 12.5049 26.2749 12.3835 26.3253C12.2621 26.3756 12.132 26.4015 12.0006 26.4015C11.8691 26.4015 11.739 26.3756 11.6176 26.3253C11.4962 26.2749 11.3859 26.2012 11.2931 26.1082L4.29306 19.1082C4.10542 18.9206 4 18.6661 4 18.4007C4 18.1353 4.10542 17.8808 4.29306 17.6932C4.4807 17.5056 4.73519 17.4001 5.00056 17.4001C5.26592 17.4001 5.52042 17.5056 5.70806 17.6932L12.0006 23.987L27.2931 8.6932C27.4807 8.50556 27.7352 8.40015 28.0006 8.40015C28.2659 8.40015 28.5204 8.50556 28.7081 8.6932C28.8957 8.88084 29.0011 9.13534 29.0011 9.4007C29.0011 9.66607 28.8957 9.92056 28.7081 10.1082Z"
        fill="#FCB25F"
      />
    </svg>
  );
};
