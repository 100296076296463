const CartCross = ({ setCartOpen }) => {
  return (
    <svg
      onClick={() => {
        setCartOpen(false);
      }}
      className="cart__cross"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.7081 24.6931C25.801 24.7861 25.8747 24.8964 25.9249 25.0177C25.9752 25.1391 26.0011 25.2692 26.0011 25.4006C26.0011 25.532 25.9752 25.6621 25.9249 25.7835C25.8747 25.9049 25.801 26.0152 25.7081 26.1081C25.6151 26.2011 25.5048 26.2748 25.3835 26.325C25.2621 26.3753 25.132 26.4012 25.0006 26.4012C24.8692 26.4012 24.7391 26.3753 24.6177 26.325C24.4963 26.2748 24.386 26.2011 24.2931 26.1081L16.0006 17.8144L7.70806 26.1081C7.52042 26.2958 7.26592 26.4012 7.00056 26.4012C6.73519 26.4012 6.4807 26.2958 6.29306 26.1081C6.10542 25.9205 6 25.666 6 25.4006C6 25.1353 6.10542 24.8808 6.29306 24.6931L14.5868 16.4006L6.29306 8.10814C6.10542 7.9205 6 7.66601 6 7.40064C6 7.13528 6.10542 6.88078 6.29306 6.69314C6.4807 6.5055 6.73519 6.40009 7.00056 6.40009C7.26592 6.40009 7.52042 6.5055 7.70806 6.69314L16.0006 14.9869L24.2931 6.69314C24.4807 6.5055 24.7352 6.40009 25.0006 6.40009C25.2659 6.40009 25.5204 6.5055 25.7081 6.69314C25.8957 6.88078 26.0011 7.13528 26.0011 7.40064C26.0011 7.66601 25.8957 7.9205 25.7081 8.10814L17.4143 16.4006L25.7081 24.6931Z"
        fill="#8C8C8C"
      />
    </svg>
  );
};

export default CartCross;
