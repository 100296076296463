const ArrowRight = () => {
    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.7806 8.53055L11.0306 15.2806C10.8899 15.4213 10.699 15.5003 10.5 15.5003C10.301 15.5003 10.1101 15.4213 9.96937 15.2806C9.82864 15.1398 9.74958 14.949 9.74958 14.7499C9.74958 14.5509 9.82864 14.36 9.96937 14.2193L15.4397 8.74993H0.75C0.551088 8.74993 0.360322 8.67091 0.21967 8.53026C0.0790178 8.38961 0 8.19884 0 7.99993C0 7.80102 0.0790178 7.61025 0.21967 7.4696C0.360322 7.32895 0.551088 7.24993 0.75 7.24993H15.4397L9.96937 1.78055C9.82864 1.63982 9.74958 1.44895 9.74958 1.24993C9.74958 1.05091 9.82864 0.860034 9.96937 0.719304C10.1101 0.578573 10.301 0.499512 10.5 0.499512C10.699 0.499512 10.8899 0.578573 11.0306 0.719304L17.7806 7.4693C17.8504 7.53896 17.9057 7.62167 17.9434 7.71272C17.9812 7.80377 18.0006 7.90137 18.0006 7.99993C18.0006 8.09849 17.9812 8.19608 17.9434 8.28713C17.9057 8.37818 17.8504 8.4609 17.7806 8.53055Z"
                fill="#22252B"/>
        </svg>
    )
}

export default ArrowRight;