import { useState } from "react";
import { useForm } from "react-hook-form";
import { createReview } from "../../services/axios.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const defaultValues = {
    id: "",
    name: "",
    text: "",
    rating: ""
};

export const ReviewCreate = () => {
  const { register, handleSubmit } = useForm({ defaultValues });

  const notify = () => toast.success("Success!");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);

  const onFormSubmit = (data) => {
    data.rating = +data.rating || 5
    createReview({ ...data, date});
    notify();
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="panel__form">
        <div className="panel__input--wrapper">
            <input
                type="text"
                className="model__panel--input"
                {...register("id", {required: true})}
                placeholder="ID"
            />
            <input
                type="text"
                className="model__panel--input"
                {...register("name", {required: true})}
                placeholder="Name"
            />
            <input
                type="text"
                className="model__panel--input"
                {...register("text", {required: true})}
                placeholder="text"
            />
            <input
                type="text"
                className="model__panel--input"
                {...register("rating", {required: true})}
                placeholder="Rating"
            />
            <input
                type="date"
                className="model__panel--input"
                placeholder="Date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
            />
        </div>
        <button
            type="submit"
            className="model__panel--button"
            style={{margin: "25px auto 0"}}
        >
            Save
        </button>
    </form>
  );
};
