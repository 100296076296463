import SlidingPanel from "react-sliding-side-panel";
import "react-sliding-side-panel/lib/index.css";
import { Link } from "react-router-dom";
import IntroArrowIcon from "../../assets/icons/IntroArrowIcon";
import InstagramIcon from "../../assets/icons/InstagramIcon";
import FacebookIcon from "../../assets/icons/FacebookIcon";
import TiktokIcon from "../../assets/icons/TiktokIcon";
import { useEffect } from "react";

const useLockBodyScroll = (isLocked) => {
  useEffect(() => {
    if (isLocked) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isLocked]);
};

const SideBar = ({ isOpen, onClose }) => {
  useLockBodyScroll(isOpen);

  return (
    <SlidingPanel
      type="left"
      isOpen={isOpen}
      size={36.1}
      backdropClicked={onClose}
    >
      <div className="sidebar__content">
        <ul className="sidebar__menu">
          <Link to={`/`} className="sidebar__link">
            <h3 className="sideBar__title">Перуки</h3>
            <IntroArrowIcon />
          </Link>
          <Link to={`/`} className="sidebar__link">
            <h3 className="sidebar__title">Хвостики</h3>
            <IntroArrowIcon />
          </Link>
          <Link to={`/`} className="sidebar__link">
            <h3 className="sidebar__title">Топери</h3>
            <IntroArrowIcon />
          </Link>
          <Link to={`/`} className="sidebar__link">
            <h3 className="sidebar__title">Sale</h3>
            <IntroArrowIcon />
          </Link>
        </ul>

        <div className="sidebar__social--icons">
          <a
            href="https://instagram.com/princess.wigs"
            target="_blank"
            rel="noreferrer"
            className="sidebar__icon"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://facebook.com/princesswigsstore"
            target="_blank"
            rel="noreferrer"
            className="sidebar__icon"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.tiktok.com/@princess..wigs"
            target="_blank"
            rel="noreferrer"
            className="sidebar__icon"
          >
            <TiktokIcon />
          </a>
        </div>
      </div>
    </SlidingPanel>
  );
};

export default SideBar;
