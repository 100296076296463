const CrossIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.0675 12.1828C13.1256 12.2409 13.1717 12.3098 13.2031 12.3857C13.2345 12.4615 13.2507 12.5429 13.2507 12.625C13.2507 12.7071 13.2345 12.7884 13.2031 12.8643C13.1717 12.9402 13.1256 13.0091 13.0675 13.0672C13.0095 13.1252 12.9405 13.1713 12.8647 13.2027C12.7888 13.2342 12.7075 13.2503 12.6253 13.2503C12.5432 13.2503 12.4619 13.2342 12.386 13.2027C12.3102 13.1713 12.2412 13.1252 12.1832 13.0672L7.00035 7.88357L1.81753 13.0672C1.70026 13.1844 1.5412 13.2503 1.37535 13.2503C1.2095 13.2503 1.05044 13.1844 0.93316 13.0672C0.815885 12.9499 0.75 12.7908 0.75 12.625C0.75 12.4591 0.815885 12.3001 0.93316 12.1828L6.11675 6.99998L0.93316 1.81717C0.815885 1.69989 0.75 1.54083 0.75 1.37498C0.75 1.20913 0.815885 1.05007 0.93316 0.932794C1.05044 0.815518 1.2095 0.749634 1.37535 0.749634C1.5412 0.749634 1.70026 0.815518 1.81753 0.932794L7.00035 6.11639L12.1832 0.932794C12.3004 0.815518 12.4595 0.749634 12.6253 0.749634C12.7912 0.749634 12.9503 0.815518 13.0675 0.932794C13.1848 1.05007 13.2507 1.20913 13.2507 1.37498C13.2507 1.54083 13.1848 1.69989 13.0675 1.81717L7.88394 6.99998L13.0675 12.1828Z"
                fill="white"/>
        </svg>

    )
}

export default CrossIcon;