import {
  getCart,
  getModelsByIdsArray,
  updateCart,
  getPromocode,
} from "../../../services/axios";
import { useEffect, useState } from "react";
import CartCross from "../../../assets/icons/CartCross.jsx";
import { EmptyCart } from "../EmptyCart/EmptyCart.jsx";
import { LastSeenModels } from "../LastSeenModels/LastSeenModels.jsx";
import stars0 from "../../../assets/images/red-stars.png";
import stars20 from "../../../assets/images/red-stars20.png";
import stars40 from "../../../assets/images/red-stars40.png";
import stars60 from "../../../assets/images/red-stars60.png";
import stars80 from "../../../assets/images/red-stars80.png";
import stars100 from "../../../assets/images/red-stars100.png";
import { CartPlusIcon } from "../../../assets/icons/CartPlusIcon.jsx";
import { CartMinusIcon } from "../../../assets/icons/CartMinusIcon.jsx";
import { CartBinIcon } from "../../../assets/icons/CartBinIcon.jsx";
import { PromocodeApplied } from "../../../assets/icons/PromocodeApplied.jsx";

export const Data = ({ setCartOpen }) => {
  const [scroll, setScroll] = useState(false);
  const [items, setItems] = useState([]);
  const [update, setUpdate] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [total, setTotal] = useState(0);
  const [models, setModels] = useState([]);
  const [promocode, setPromocode] = useState("");
  const [promocodeActivated, setPromocodeActivated] = useState(
    localStorage.getItem("promocodeActivated")
      ? JSON.parse(localStorage.getItem("promocodeActivated"))
      : null
  );
  const [promocodeError, setPromocodeError] = useState(false);

  useEffect(() => {
    const fetchCart = async () => {
      const data = await getCart();
      if (data) {
        if (data?.cart?.length > 2) {
          setScroll(true);
        }
        let totalPrice = 0;
        let applyDiscount = false;
        let alreadyApplied = false;

        let singleQuantityItems = data?.cart.filter(
          (item) => item.quantity === 1
        );
        if (
          data?.cart.some((item) => item.quantity > 1) ||
          singleQuantityItems?.length > 1
        ) {
          applyDiscount = true;
        }

        let cheapestItem = applyDiscount
          ? data?.cart.reduce(
              (min, item) => (item.price < min.price ? item : min),
              data?.cart[0]
            )
          : null;

        data?.cart.forEach((item) => {
          let itemTotalPrice = item.price * item.quantity;

          if (
            applyDiscount &&
            !alreadyApplied &&
            item.id === cheapestItem?.id
          ) {
            itemTotalPrice =
              item.price * (item.quantity - 1) + item.price * 0.8;
            alreadyApplied = true;
          }

          totalPrice += itemTotalPrice;
        });
        setTotal(totalPrice.toFixed(0));

        setItems(data.cart);
      }
    };

    fetchCart();
  }, []);

  useEffect(() => {
    const fetchCart = async () => {
      const data = await getCart();
      if (data?.cart?.length > 2) {
        setScroll(true);
      }
      let totalPrice = 0;
      let applyDiscount = false;
      let alreadyApplied = false;

      let singleQuantityItems = data?.cart.filter(
        (item) => item.quantity === 1
      );
      if (
        data?.cart.some((item) => item.quantity > 1) ||
        singleQuantityItems.length > 1
      ) {
        applyDiscount = true;
      }

      let cheapestItem = applyDiscount
        ? data?.cart.reduce(
            (min, item) => (item.price < min.price ? item : min),
            data?.cart[0]
          )
        : null;

      data?.cart.forEach((item) => {
        let itemTotalPrice = item.price * item.quantity;

        if (applyDiscount && !alreadyApplied && item.id === cheapestItem?.id) {
          itemTotalPrice = item.price * (item.quantity - 1) + item.price * 0.8;
          alreadyApplied = true;
        }

        totalPrice += itemTotalPrice;
      });
      setTotal(totalPrice.toFixed(0));
      setItems(data.cart);
    };
    if (refresh) {
      fetchCart();
      setRefresh(false);
    }
    if (update) {
      updateCart(items);
      setUpdate(false);
    }
  }, [update, refresh]);

  const handleClick = (index, delta) => {
    setItems((prevItems) => {
      const el = prevItems
        .map((item, i) => {
          return i === index
            ? item.quantity + delta === 0
              ? {}
              : { ...item, quantity: item.quantity + delta }
            : item;
        })
        .filter((obj) => Object.keys(obj).length !== 0);
      if (el.length > 2) {
        setScroll(true);
      } else {
        setScroll(false);
      }
      let totalPrice = 0;
      let applyDiscount = false;
      let alreadyApplied = false;

      let singleQuantityItems = el.filter((item) => item.quantity === 1);
      if (
        el.some((item) => item.quantity > 1) ||
        singleQuantityItems.length > 1
      ) {
        applyDiscount = true;
      }

      let cheapestItem = applyDiscount
        ? el.reduce((min, item) => (item.price < min.price ? item : min), el[0])
        : null;

      el.forEach((item) => {
        let itemTotalPrice = item.price * item.quantity;

        if (applyDiscount && !alreadyApplied && item.id === cheapestItem?.id) {
          itemTotalPrice = item.price * (item.quantity - 1) + item.price * 0.8;
          alreadyApplied = true;
        }

        totalPrice += itemTotalPrice;
      });
      setTotal(totalPrice.toFixed(0));

      return el;
    });
    setUpdate(true);
  };

  useEffect(() => {
    let modelsIds = JSON.parse(localStorage.getItem("lastSeenModels")) || [];

    const fetchModels = async () => {
      setModels(await getModelsByIdsArray(modelsIds));
    };

    fetchModels();
  }, []);

  return (
    <div className="cart__modal">
      <div className="cart__title--wrapper">
        <p className="cart__title">Кошик</p>
        <CartCross setCartOpen={setCartOpen} />
      </div>
      <div className="cart__line"></div>
      {items.length === 0 && <EmptyCart models={models} />}
      {items.length > 0 && (
        <>
          <div
            className="cart__promocode--wrapper"
            style={promocodeActivated ? { padding: "16px 24px" } : {}}
          >
            {promocodeActivated ? (
              <div className="cart__promocode--applied">
                <PromocodeApplied />
                <p className="cart__promocode--text">
                  Маєш промокод - Активуй зараз!
                </p>
              </div>
            ) : (
              <>
                <p className="cart__promocode--text">
                  Маєш промокод - Активуй зараз!
                </p>
                <div className="cart__promocode--thumb">
                  <input
                    type="text"
                    placeholder="Введіть промокод"
                    className={
                      promocodeError
                        ? "cart__promocode--input cart__promocode--error"
                        : "cart__promocode--input"
                    }
                    value={promocode}
                    onChange={(e) => {
                      setPromocode(e.target.value);
                    }}
                  />
                  <button
                    type="button"
                    className="cart__promocode--button"
                    onClick={async () => {
                      const result = await getPromocode(promocode);
                      if (result) {
                        localStorage.setItem(
                          "promocodeActivated",
                          JSON.stringify(result)
                        );
                        setPromocodeActivated(result);
                      } else {
                        setPromocodeError(true);
                      }
                    }}
                  >
                    Активувати
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="cart__list--wrapper">
            <ul
              className="cart__list"
              style={scroll ? { overflowY: "scroll" } : {}}
            >
              {items.map(
                (
                  {
                    image,
                    name,
                    price,
                    color,
                    quantity,
                    description,
                    score,
                    reviewsNumber,
                  },
                  index
                ) => {
                  let scoreImg = "";
                  if (score === "4" || score === "4,1") {
                    scoreImg = stars0;
                  } else if (score === "4,2" || score === "4,3") {
                    scoreImg = stars20;
                  } else if (score === "4,4" || score === "4,5") {
                    scoreImg = stars40;
                  } else if (score === "4,6" || score === "4,7") {
                    scoreImg = stars60;
                  } else if (score === "4,8" || score === "4,9") {
                    scoreImg = stars80;
                  } else if (score === "5") {
                    scoreImg = stars100;
                  }
                  return (
                    <li className="cart__item" key={index}>
                      <div className="cart__item--thumb">
                        <img
                          src={image}
                          alt="model"
                          className="cart__model--image"
                        />
                        <div className="cart__model--info">
                          <p className="cart__model--name">
                            {name} {color}
                          </p>
                          <div className="cart__start--wrapper">
                            <img src={scoreImg} alt="score" />
                            {reviewsNumber && (
                              <p className={"model__reviews--number"}>
                                {`(${reviewsNumber}) відгуки`}
                              </p>
                            )}
                          </div>
                          <p className="cart__item--description">
                            {description}
                          </p>
                        </div>
                      </div>
                      <div className="cart__thumb">
                        <div className="cart__quantity--wrapper">
                          <CartMinusIcon
                            handleClick={handleClick}
                            index={index}
                          />
                          <div className="cart__quantity--thumb">
                            <p className="cart__model--quantity">{quantity}</p>
                          </div>
                          <CartPlusIcon
                            handleClick={handleClick}
                            index={index}
                          />
                        </div>
                      </div>
                      <div className="cart__item--wrapper">
                        <CartBinIcon
                          handleClick={handleClick}
                          index={index}
                          quantity={quantity}
                        />
                        <p className="cart__model--price">{price} ГРН</p>
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
            <div className="cart__checkout--wrapper">
              <button
                type="button"
                className="cart__back--button"
                onClick={() => {
                  setCartOpen(false);
                }}
              >
                Продовжити покупки
              </button>
              <div className="cart__checkout--thumb">
                <div className="cart__price--wrapper">
                  {promocodeActivated?.type === "discount" && (
                    <p className="cart__checkout--oldTotal">
                      <s>{total}₴</s>
                    </p>
                  )}
                  <p className="cart__checkout--total">
                    {promocodeActivated
                      ? promocodeActivated?.type === "discount"
                        ? Math.ceil(
                            total * (1 - promocodeActivated?.discount / 100)
                          )
                        : total
                      : total}
                    грн
                  </p>
                </div>
                <button
                  type="button"
                  className="cart__checkout--button"
                  onClick={() => {
                    window.location.href = "/cart";
                  }}
                >
                  Оформити замовлення
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {models.length > 0 && items.length === 0 && (
        <LastSeenModels models={models} setCartOpen={setCartOpen} />
      )}
    </div>
  );
};
