import SlidingPanel from "react-sliding-side-panel";
import "react-sliding-side-panel/lib/index.css";
import { Link, NavLink } from "react-router-dom";
import IntroArrowIcon from "../../assets/icons/IntroArrowIcon";
import InstagramIcon from "../../assets/icons/InstagramIcon";
import FacebookIcon from "../../assets/icons/FacebookIcon";
import TiktokIcon from "../../assets/icons/TiktokIcon";
import SalesIcon from "../../assets/icons/SalesIcon";
import LogoIcon from "../../assets/icons/LogoIcon";
import CloseIcon from "../../assets/icons/CloseIcon";
import { useEffect } from "react";

const MobileSideBar = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  return (
    <SlidingPanel
      type="left"
      isOpen={isOpen}
      size={87}
      backdropClicked={onClose}
    >
      <div className="sidebar__content">
        <div className="sidebar__top">
          <NavLink to="/" className="sidebar__logo--link">
            <LogoIcon />
          </NavLink>
          <button className="sidebar__btn--close" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <h2 className="sidebar__caption">Каталог</h2>
        <ul className="sidebar__menu">
          <Link to={`/`} className="sidebar__link">
            <h3 className="sideBar__title">Перуки</h3>
            <IntroArrowIcon />
          </Link>
          <Link to={`/`} className="sidebar__link">
            <h3 className="sidebar__title">Хвостики</h3>
            <IntroArrowIcon />
          </Link>
          <Link to={`/`} className="sidebar__link">
            <h3 className="sidebar__title">Топери</h3>
            <IntroArrowIcon />
          </Link>
          <Link to={`/`} className="sidebar__link">
            <h3 className="sidebar__title">Sale</h3>
            <IntroArrowIcon />
          </Link>
        </ul>
        <nav className="sidebar__nav">
          <ul className="sidebar__nav--list">
            {/* <NavLink to="/" className="sidebar__nav--link">
              <li className="sidebar__nav--item sidebar__nav--item--sales">
                <SalesIcon />
                Акції
              </li>
            </NavLink> */}
            <NavLink to="/" className="sidebar__nav--link">
              <li className="sidebar__nav--item">Про нас</li>
            </NavLink>
            <NavLink to="/" className="sidebar__nav--link">
              <li className="sidebar__nav--item">Відгуки</li>
            </NavLink>
            <NavLink to="/" className="sidebar__nav--link">
              <li className="sidebar__nav--item">Доставка і оплата</li>
            </NavLink>
            <NavLink to="/" className="sidebar__nav--link">
              <li className="sidebar__nav--item">Обмін і повернення</li>
            </NavLink>
            <NavLink to="/" className="sidebar__nav--link">
              <li className="sidebar__nav--item">Контакти</li>
            </NavLink>
          </ul>
        </nav>
        <div className="sidebar__bottom">
          <div className="sidebar__contact">
            <h3 className="sidebar__contact--title">Зворотний зв'язок</h3>
            <p className="sidebar__contact--text">
              Ви можете{" "}
              <a
                className="sidebar__contact--link"
                href="https://ig.me/m/princess.wigs"
                target="_blank"
                rel="noreferrer"
              >
                написати нам
              </a>{" "}
              або замовити зворотний дзвінок
            </p>
          </div>
          <div className="sidebar__social--icons">
            <a
              href="https://instagram.com/princess.wigs"
              target="_blank"
              rel="noreferrer"
              className="sidebar__icon"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://facebook.com/princesswigsstore"
              target="_blank"
              rel="noreferrer"
              className="sidebar__icon"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.tiktok.com/@princess..wigs"
              target="_blank"
              rel="noreferrer"
              className="sidebar__icon"
            >
              <TiktokIcon />
            </a>
          </div>
        </div>
      </div>
    </SlidingPanel>
  );
};

export default MobileSideBar;
