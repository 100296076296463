import { NavLink } from "react-router-dom";

export const HeaderPanel = () => {
  return (
    <header className="header__panel container" style={{ padding: "0" }}>
      <NavLink to="/smk147uoljowb/model" className="header__tab">
        Model
      </NavLink>
      <NavLink to="/smk147uoljowb/new" className="header__tab">
        New Section
      </NavLink>
      <NavLink to="/smk147uoljowb/filter" className="header__tab">
        Filter
      </NavLink>
      <NavLink to="/smk147uoljowb/promocode" className="header__tab">
        Promocode
      </NavLink>
      <NavLink to="/smk147uoljowb/reviews" className="header__tab">
        Reviews
      </NavLink>
      <NavLink to="/smk147uoljowb/blocks" className="header__tab">
          Blocks
      </NavLink>
        <NavLink to="/smk147uoljowb/fastFilter" className="header__tab">
            FastFilter
        </NavLink>
    </header>
  );
};
