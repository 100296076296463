import { useForm } from "react-hook-form";
import { updatePromocode } from "../../services/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const PromocodeEdit = ({ item }) => {
  console.log(item);
  const defaultValues = {
    promocode: item.promocode,
    type: item.type,
    discount: item.discount,
  };
  const { register, handleSubmit } = useForm({ defaultValues });

  const notify = () => toast.success("Success!");

  const onFormSubmit = (data) => {
    updatePromocode({ _id: item._id, ...data });

    notify();
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="panel__form">
      <div className="panel__input--wrapper">
        <input
          type="text"
          className="model__panel--input"
          {...register("promocode", { required: true })}
          placeholder="promocode"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("type", { required: true })}
          placeholder="type"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("discount", { required: true })}
          placeholder="discount"
        />
      </div>
      <button
        type="submit"
        className="model__panel--button"
        style={{ margin: "25px auto 0" }}
      >
        Save
      </button>
    </form>
  );
};
