import {useEffect, useState} from "react";
import {
    getAllBlocks,
    getOneBlock,
    hideBlock,
    tryLogin
} from "../../services/axios.js";
import {toast, ToastContainer} from "react-toastify";
import {EditBlock} from "../../components/EditBlock/EditBlock.jsx";
import {CreateBlock} from "../../components/CreateBlock/CreateBlock.jsx";


export const BlocksPanel = () => {
    const [page, setPage] = useState("base");
    const [blocks, setBlocks] = useState([]);
    const [block, setBlock] = useState(null);

    useEffect(() => {
        const tryGetAccess = async () => {
            const password = localStorage.getItem("passwordToAdminPanel");
            if (password) {
                if ((await tryLogin(password)) === "denied") {
                    window.location.href = "/";
                }
            } else {
                window.location.href = "/";
            }
        };

        tryGetAccess();
    }, []);

    const notify = () => toast.success("Success!");

    useEffect(() => {
        const fetchBlocks = async () => {
            const fetchedNewSections = await getAllBlocks();
            setBlocks(fetchedNewSections);
        };
        fetchBlocks();
    }, []);

    return (
        <div className="container" style={{ minHeight: "100vh", padding: "0" }}>
            {page === "base" ? (
                <>
                    <button
                        type="button"
                        className="model__panel--button"
                        onClick={() => {
                            setPage("create");
                        }}
                    >
                        Створити
                    </button>
                    <div className="panel__list">
                        {blocks?.map(({ _id, id, image, title, show }) => (
                            <div className="panel__item--wrapper" key={_id}>
                                <div
                                    style={
                                        show
                                            ? { backgroundColor: "#fcb25f" }
                                            : {
                                                backgroundColor: "rgb(189, 193, 198)",
                                                border: "rgb(93, 93, 93)",
                                            }
                                    }
                                    key={_id}
                                    className="panel__item"
                                    onClick={async () => {
                                        setBlock(await getOneBlock(_id));
                                        setPage("edit");
                                    }}
                                >
                                    <div className="panel__id--wrapper">
                                        <p className="panel__id">{id}</p>
                                    </div>
                                    <img src={image} alt={title} className="panel__image" />
                                    <p className="panel__name">{title}</p>
                                </div>
                                <button
                                    className="panel__button--hide"
                                    onClick={() => {
                                        hideBlock(_id, !show);
                                        notify();
                                    }}
                                >
                                    {show ? "Active" : "Hidden"}
                                </button>
                            </div>
                        ))}
                    </div>
                </>
            ) : page === "create" ? (
                 <CreateBlock />
             ) : (
                <EditBlock block={block} />
             )}
            <ToastContainer />
        </div>
    );
}