import { useEffect, useState } from "react";
import {
  getCart,
  getModelsByIdsArray,
  updateCart,
  getPromocode,
} from "../../services/axios";
import Header from "../../components/Header/Header";
import CartCross from "../../assets/icons/CartCross.jsx";
import stars0 from "../../assets/images/red-stars.png";
import stars20 from "../../assets/images/red-stars20.png";
import stars40 from "../../assets/images/red-stars40.png";
import stars60 from "../../assets/images/red-stars60.png";
import stars80 from "../../assets/images/red-stars80.png";
import stars100 from "../../assets/images/red-stars100.png";
import { CartPlusIcon } from "../../assets/icons/CartPlusIcon.jsx";
import { CartMinusIcon } from "../../assets/icons/CartMinusIcon.jsx";
import { CartBinIcon } from "../../assets/icons/CartBinIcon.jsx";
import { PromocodeApplied } from "../../assets/icons/PromocodeApplied.jsx";
import NovaPoshtaInput from "../../components/Cart/NovaPoshtaInput/NovaPoshtaInput.jsx";
import { CheckoutPromocodePlusIcon } from "../../assets/icons/CheckoutPromocodePlusIcon.jsx";
import { CheckoutPromocodeCrossIcon } from "../../assets/icons/CheckoutPromocodeCrossIcon.jsx";
import { sendCallback } from "../../services/monobank.js";

const Checkout = ({ setCartOpen }) => {
  const [scroll, setScroll] = useState(false);
  const [items, setItems] = useState([]);
  const [update, setUpdate] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [total, setTotal] = useState(0);
  const [models, setModels] = useState([]);
  const [promocode, setPromocode] = useState("");
  const [promocodeActivated, setPromocodeActivated] = useState(
    localStorage.getItem("promocodeActivated")
      ? JSON.parse(localStorage.getItem("promocodeActivated"))
      : null
  );
  const [promocodeError, setPromocodeError] = useState(false);
  const [phone, setPhone] = useState("");
  const [bayer_name, setBayerName] = useState("");
  const [city, setCity] = useState("");
  const [branch, setBranch] = useState("");
  const [payment, setPayment] = useState("offline");
  const [promocodeOpened, setPromocodeOpened] = useState(false);

  function isNumber(value) {
    return typeof value === "number" && !isNaN(value);
  }

  const onNameChange = (e) => {
    setBayerName(e.target.value);
  };

  const onPhoneChange = (e) => {
    if (e.target.value.trim() === "") {
      setPhone("");
    } else {
      const newValue = +e.target.value.trim();
      if (isNumber(newValue)) {
        if (
          e.target.value.length > 0 &&
          isNumber(+e.target.value[e.target.value.length - 1])
        ) {
          setPhone(e.target.value.trim());
        }
      }
    }
  };

  useEffect(() => {
    const fetchCart = async () => {
      const data = await getCart();
      if (data) {
        if (data?.cart?.length > 2) {
          setScroll(true);
        }
        let totalPrice = 0;
        let applyDiscount = false;
        let alreadyApplied = false;

        let singleQuantityItems = data?.cart.filter(
          (item) => item.quantity === 1
        );
        if (
          data?.cart.some((item) => item.quantity > 1) ||
          singleQuantityItems?.length > 1
        ) {
          applyDiscount = true;
        }

        let cheapestItem = applyDiscount
          ? data?.cart.reduce(
              (min, item) => (item.price < min.price ? item : min),
              data?.cart[0]
            )
          : null;

        data?.cart.forEach((item) => {
          let itemTotalPrice = item.price * item.quantity;

          if (
            applyDiscount &&
            !alreadyApplied &&
            item.id === cheapestItem?.id
          ) {
            itemTotalPrice =
              item.price * (item.quantity - 1) + item.price * 0.8;
            alreadyApplied = true;
          }

          totalPrice += itemTotalPrice;
        });

        setTotal(totalPrice.toFixed(0));

        setItems(data.cart);
      }
    };

    fetchCart();
  }, []);

  useEffect(() => {
    const fetchCart = async () => {
      const data = await getCart();
      if (data?.cart?.length > 2) {
        setScroll(true);
      }
      let totalPrice = 0;
      let applyDiscount = false;
      let alreadyApplied = false;

      let singleQuantityItems = data?.cart.filter(
        (item) => item.quantity === 1
      );
      if (
        data?.cart.some((item) => item.quantity > 1) ||
        singleQuantityItems.length > 1
      ) {
        applyDiscount = true;
      }

      let cheapestItem = applyDiscount
        ? data?.cart.reduce(
            (min, item) => (item.price < min.price ? item : min),
            data?.cart[0]
          )
        : null;

      data?.cart.forEach((item) => {
        let itemTotalPrice = item.price * item.quantity;

        if (applyDiscount && !alreadyApplied && item.id === cheapestItem?.id) {
          itemTotalPrice = item.price * (item.quantity - 1) + item.price * 0.8;
          alreadyApplied = true;
        }

        totalPrice += itemTotalPrice;
      });
      setTotal(totalPrice.toFixed(0));
      setItems(data.cart);
    };
    if (refresh) {
      fetchCart();
      setRefresh(false);
    }
    if (update) {
      updateCart(items);
      setUpdate(false);
    }
  }, [update, refresh]);

  const handleClick = (index, delta) => {
    setItems((prevItems) => {
      const el = prevItems
        .map((item, i) => {
          return i === index
            ? item.quantity + delta === 0
              ? {}
              : { ...item, quantity: item.quantity + delta }
            : item;
        })
        .filter((obj) => Object.keys(obj).length !== 0);
      if (el.length > 2) {
        setScroll(true);
      } else {
        setScroll(false);
      }
      let totalPrice = 0;
      let applyDiscount = false;
      let alreadyApplied = false;

      let singleQuantityItems = el.filter((item) => item.quantity === 1);
      if (
        el.some((item) => item.quantity > 1) ||
        singleQuantityItems.length > 1
      ) {
        applyDiscount = true;
      }

      let cheapestItem = applyDiscount
        ? el.reduce((min, item) => (item.price < min.price ? item : min), el[0])
        : null;

      el.forEach((item) => {
        let itemTotalPrice = item.price * item.quantity;

        if (applyDiscount && !alreadyApplied && item.id === cheapestItem?.id) {
          itemTotalPrice = item.price * (item.quantity - 1) + item.price * 0.8;
          alreadyApplied = true;
        }

        totalPrice += itemTotalPrice;
      });
      setTotal(totalPrice.toFixed(0));

      return el;
    });
    setUpdate(true);
  };

  useEffect(() => {
    let modelsIds = JSON.parse(localStorage.getItem("lastSeenModels")) || [];

    const fetchModels = async () => {
      setModels(await getModelsByIdsArray(modelsIds));
    };

    fetchModels();
  }, []);

  return (
    <>
      <Header setCartOpen={setCartOpen} />
      <section className="checkout">
        <div className="container checkout__wrapper">
          <div className="checkout__main">
            <p className="checkout__title">Оформити замовлення</p>
            {items.length > 0 && (
              <>
                <div>
                  <ul className="checkout__list">
                    {items.map(
                      (
                        {
                          image,
                          name,
                          price,
                          color,
                          quantity,
                          description,
                          score,
                          reviewsNumber,
                        },
                        index
                      ) => {
                        let scoreImg = "";
                        if (score === "4" || score === "4,1") {
                          scoreImg = stars0;
                        } else if (score === "4,2" || score === "4,3") {
                          scoreImg = stars20;
                        } else if (score === "4,4" || score === "4,5") {
                          scoreImg = stars40;
                        } else if (score === "4,6" || score === "4,7") {
                          scoreImg = stars60;
                        } else if (score === "4,8" || score === "4,9") {
                          scoreImg = stars80;
                        } else if (score === "5") {
                          scoreImg = stars100;
                        }
                        return (
                          <li className="checkout__item" key={index}>
                            <div className="cart__item--thumb">
                              <img
                                src={image}
                                alt="model"
                                className="cart__model--image"
                              />
                              <div className="checkout__model--info">
                                <p className="cart__model--name">
                                  {name} {color}
                                </p>
                                <div className="cart__start--wrapper">
                                  <img src={scoreImg} alt="score" />
                                  {reviewsNumber && (
                                    <p className={"model__reviews--number"}>
                                      {`(${reviewsNumber}) відгуки`}
                                    </p>
                                  )}
                                </div>
                                <p className="cart__item--description">
                                  {description}
                                </p>
                              </div>
                            </div>
                            <div className="cart__thumb">
                              <div className="cart__quantity--wrapper">
                                <CartMinusIcon
                                  handleClick={handleClick}
                                  index={index}
                                />
                                <div className="cart__quantity--thumb">
                                  <p className="cart__model--quantity">
                                    {quantity}
                                  </p>
                                </div>
                                <CartPlusIcon
                                  handleClick={handleClick}
                                  index={index}
                                />
                              </div>
                            </div>
                            <div className="cart__item--wrapper">
                              <CartBinIcon
                                handleClick={handleClick}
                                index={index}
                                quantity={quantity}
                              />
                              <p className="cart__model--price">{price} ГРН</p>
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                  <div className="checkout__receiver--wrapper">
                    <p className="checkout__subtitle">Отримувач</p>
                    <div className="checkout__name--wrapper">
                      <div className="checkout__name--thumb">
                        <p className="checkout__name">ПІБ</p>
                        <input
                          type="text"
                          placeholder="Ваше ПІБ"
                          className="checkout__input--name"
                          value={bayer_name}
                          onChange={onNameChange}
                        />
                      </div>
                      <div className="checkout__name--thumb">
                        <p className="checkout__name">Номер телефону</p>
                        <input
                          type="text"
                          placeholder="+38 (095) 555 - 55 - 55"
                          className="checkout__input--name"
                          value={phone}
                          onChange={onPhoneChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="novaPoshta__wrappper">
                    <p className="checkout__subtitle">Доставка</p>
                    <NovaPoshtaInput
                      city={city}
                      setCity={setCity}
                      branch={branch}
                      setBranch={setBranch}
                    />
                  </div>
                  <div>
                    <p className="checkout__subtitle">Оплата</p>
                    <div className="checkout__payment--thumb">
                      <div
                        className="checkout__payment--wrapper"
                        onClick={() => {
                          setPayment("offline");
                        }}
                      >
                        <div className="novaPoshta__method--choose">
                          {payment === "offline" && (
                            <div className="novaPoshta__method--choosen"></div>
                          )}
                        </div>
                        <p className="novaPoshta__option">
                          Оплата при отриманні (Накладений платіж)
                        </p>
                      </div>
                      <div
                        className="checkout__payment--wrapper"
                        onClick={() => {
                          setPayment("online");
                        }}
                      >
                        <div className="novaPoshta__method--choose">
                          {payment === "online" && (
                            <div className="novaPoshta__method--choosen"></div>
                          )}
                        </div>
                        <p className="novaPoshta__option">Оплата онлайн</p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="checkout__thumb">
            <div className="checkout__promocode--wrapper">
              {promocodeActivated ? (
                <div className="checkout__promocode--applied">
                  <p className="checkout__promocode--text">
                    Промокод активний!
                  </p>
                  <PromocodeApplied />
                </div>
              ) : (
                <>
                  <div className="checkout__promocode--top">
                    <p className="checkout__promocode--text">Промокод</p>
                    {promocodeOpened ? (
                      <button
                        type="button"
                        className="checkout__promocode--open"
                        onClick={() => {
                          setPromocodeOpened(false);
                        }}
                      >
                        Закрити <CheckoutPromocodeCrossIcon />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="checkout__promocode--open"
                        onClick={() => {
                          setPromocodeOpened(true);
                        }}
                      >
                        Застосувати <CheckoutPromocodePlusIcon />
                      </button>
                    )}
                  </div>
                  {promocodeOpened && (
                    <div className="checkout__promocode--opened">
                      <input
                        type="text"
                        placeholder="Введіть промокод"
                        className={
                          promocodeError
                            ? "checkout__promocode--input cart__promocode--error"
                            : "checkout__promocode--input"
                        }
                        value={promocode}
                        onChange={(e) => {
                          setPromocode(e.target.value);
                        }}
                      />
                      <button
                        type="button"
                        className="checkout__promocode--button"
                        onClick={async () => {
                          const result = await getPromocode(promocode);
                          if (result) {
                            localStorage.setItem(
                              "promocodeActivated",
                              JSON.stringify(result)
                            );
                            setPromocodeActivated(result);
                          } else {
                            setPromocodeError(true);
                          }
                        }}
                      >
                        Активувати
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="checkout__data">
              <p className="checkout__data--title">Разом</p>
              <div className="checkout__delivery">
                <p className="checkout__delivery--title">Вартість доставки</p>
                <p className="checkout__delivery--value">
                  {promocodeActivated?.type === "delivery"
                    ? "Безкоштовно"
                    : "За тарифами перевізника"}
                </p>
              </div>
              <div className="checkout__data--line"></div>
              <div className="checkout__delivery">
                <p className="checkout__delivery--title">До сплати</p>
                <p className="checkout__total">
                  {promocodeActivated?.type === "discount"
                    ? Math.ceil(
                        total * (1 - promocodeActivated?.discount / 100)
                      )
                    : total}
                  грн
                </p>
              </div>
              <div className="checkout__data--line"></div>
              <button
                type="button"
                className="checkout__apply--button"
                onClick={async () => {
                  if (payment === "offline") {
                  } else {
                    const ids = [];
                    const quantities = [];
                    const prices = [];
                    let comment = "";

                    for (let i = 0; i < items.length; i += 1) {
                      ids.push(items[i].id);
                      quantities.push(items[i].quantity);
                      prices.push(items[i].price);
                      comment += items[i].name;
                      comment += ` ${items[i].color} `;
                    }
                    const amount = `${
                      promocodeActivated?.type === "discount"
                        ? Math.ceil(
                            total * (1 - promocodeActivated?.discount / 100)
                          )
                        : total
                    }00`;
                    const data = await sendCallback(
                      amount,
                      prices,
                      phone,
                      bayer_name,
                      promocodeActivated
                        ? promocodeActivated?.type === "discount"
                          ? `${comment} - промокод на знижку ${
                              promocodeActivated?.discount
                            }%  - загальна вартість ${Math.ceil(
                              total * (1 - promocodeActivated?.discount / 100)
                            )}`
                          : promocodeActivated?.type === "delivery"
                          ? `${comment} - промокод на безкоштовну доставку - загальна вартість ${total}`
                          : `${comment} - промокод на подарунок - загальна вартість ${total}`
                        : `${comment} - загальна вартість ${total}`,
                      `${city} - ${branch}`,
                      ids,
                      quantities
                    );
                    window.location.href = data.invoiceLink;
                  }
                }}
              >
                Оформити замовлення
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
