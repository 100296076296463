import axios from "axios";
import { serialize } from "php-serialize";
import { generateRandomNumber } from "../utils/generateRandomNumber";

export const sendCallback = async (
  price,
  phone,
  bayer_name,
  comment,
  id = "1"
) => {
  if (!bayer_name) {
    return;
  }

  if (!phone) {
    return;
  }
  const products = [
    {
      product_id: id,
      price,
      count: 1,
    },
  ];

  const serialized = serialize(products);
  const encoded = encodeURIComponent(serialized);

  const utm_source = localStorage.getItem("utm_source") ?? "";
  const utm_medium = localStorage.getItem("utm_medium") ?? "";
  const utm_term = localStorage.getItem("utm_term") ?? "";
  const utm_content = localStorage.getItem("utm_content") ?? "";
  const utm_campaign = localStorage.getItem("utm_campaign") ?? "";

  try {
    await axios.post(`https://svabwkanvdan.site/api/callback/send`, {
      order_id: generateRandomNumber(),
      country: "UA",
      phone,
      products: encoded,
      bayer_name,
      site: window.location.hostname,
      comment,
      utm_source,
      utm_medium,
      utm_term,
      utm_content,
      utm_campaign,
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendCallbackCart = async (
  products,
  phone,
  bayer_name,
  comment
) => {
  if (!bayer_name) {
    return;
  }

  if (!phone) {
    return;
  }

  const serialized = serialize(products);
  const encoded = encodeURIComponent(serialized);

  const utm_source = localStorage.getItem("utm_source") ?? "";
  const utm_medium = localStorage.getItem("utm_medium") ?? "";
  const utm_term = localStorage.getItem("utm_term") ?? "";
  const utm_content = localStorage.getItem("utm_content") ?? "";
  const utm_campaign = localStorage.getItem("utm_campaign") ?? "";

  try {
    await axios.post(`https://svabwkanvdan.site/api/callback/send`, {
      order_id: generateRandomNumber(),
      country: "UA",
      phone,
      products: encoded,
      bayer_name,
      site: window.location.hostname,
      comment,
      utm_source,
      utm_medium,
      utm_term,
      utm_content,
      utm_campaign,
    });
  } catch (error) {
    console.log(error);
  }
};

export const createModel = async (data, image, images, colors) => {
  try {
    await axios.post(`https://svabwkanvdan.site/api/model/new`, {
      ...data,
      image,
      images,
      colorImages: colors,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAllModels = async (show) => {
  try {
    if (show) {
      const { data } = await axios.get(
        `https://svabwkanvdan.site/api/model/all?show=${show}`
      );
      return data;
    } else {
      const { data } = await axios.get(
        `https://svabwkanvdan.site/api/model/all`
      );
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getFilteredModels = async (show, filter) => {
  try {
    if (show) {
      const { data } = await axios.post(
        `https://svabwkanvdan.site/api/model/all?show=${show}`,
        filter
      );
      return data;
    } else {
      const { data } = await axios.post(
        `https://svabwkanvdan.site/api/model/all`,
        filter
      );
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getModelsByFastFilter = async (idsArray, reqData) => {
  try {
    const { data } = await axios.post(
      `https://svabwkanvdan.site/api/model/byFastFilter`,
      {
        fastFilters: idsArray,
        ...reqData,
      }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getModelsByIdsArray = async (idsArray) => {
  try {
    const { data } = await axios.post(
      `https://svabwkanvdan.site/api/model/byIds`,
      {
        idsArray,
      }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getModel = async (_id) => {
  try {
    const { data } = await axios.get(
      `https://svabwkanvdan.site/api/model/current/${_id}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

// export const deleteModel = async (_id) => {
//   try {
//     const { data } = await axios.get(
//       `https://svabwkanvdan.site/api/model/delete/${_id}`
//     );
//     return data;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const updateModel = async (data, image, images, colors) => {
  try {
    await axios.patch(`https://svabwkanvdan.site/api/model/update`, {
      ...data,
      image,
      images,
      colorImages: colors,
    });
  } catch (error) {
    console.log(error);
  }
};

export const hideModel = async (_id, show) => {
  try {
    await axios.patch(`https://svabwkanvdan.site/api/model/hide`, {
      _id,
      show,
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendImage = async (image) => {
  try {
    const { data } = await axios.post(
      `https://svabwkanvdan.site/api/image/send`,
      {
        image,
      }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createNewSection = async (data) => {
  try {
    await axios.post(`https://svabwkanvdan.site/api/new/new`, data);
  } catch (error) {
    console.log(error);
  }
};

export const getNewSections = async (show) => {
  try {
    if (show) {
      const { data } = await axios.get(
        `https://svabwkanvdan.site/api/new/all?show=${show}`
      );
      return data;
    } else {
      const { data } = await axios.get(`https://svabwkanvdan.site/api/new/all`);
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getNewSection = async (_id) => {
  try {
    const { data } = await axios.get(
      `https://svabwkanvdan.site/api/new/current/${_id}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateNewSection = async (data) => {
  try {
    await axios.patch(`https://svabwkanvdan.site/api/new/update`, data);
  } catch (error) {
    console.log(error);
  }
};

export const hideNewSection = async (_id, show) => {
  try {
    await axios.patch(`https://svabwkanvdan.site/api/new/hide`, {
      _id,
      show,
    });
  } catch (error) {
    console.log(error);
  }
};

export const tryLogin = async (password) => {
  try {
    const { data } = await axios.post(
      `https://svabwkanvdan.site/api/login/try`,
      {
        password,
      }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const sendSms = async (phone, text) => {
  try {
    const { data } = await axios.post(
      `https://api.turbosms.ua/message/send.json?token=4ec52e2cfa9174ec997470728a39cb76d02d35b9`,
      {
        recipients: [phone],
        sms: {
          sender: "Princesss",
          text,
        },
      }
    );
    console.log(data);
  } catch (error) {
    console.log(error);
  }
};

export const getFilter = async () => {
  try {
    const { data } = await axios.get(
      `https://svabwkanvdan.site/api/filter/get`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateFilter = async (data) => {
  try {
    await axios.patch(`https://svabwkanvdan.site/api/filter/update`, data);
  } catch (error) {
    console.log(error);
  }
};

export const getCart = async () => {
  const id = localStorage.getItem("userCartId");
  try {
    const { data } = await axios.get(
      `https://svabwkanvdan.site/api/cart/get?id=${id}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createCart = async (create) => {
  try {
    const { data } = await axios.post(
      `https://svabwkanvdan.site/api/cart/create`,
      create
    );
    localStorage.setItem("userCartId", data._id);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateCart = async (cart) => {
  try {
    const { data } = await axios.patch(
      `https://svabwkanvdan.site/api/cart/update`,
      {
        cart,
        _id: localStorage.getItem("userCartId"),
      }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCart = async () => {
  const _id = localStorage.getItem("userCartId");
  try {
    await axios.delete(`https://svabwkanvdan.site/api/cart/delete`, {
      _id,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAllPromocodes = async () => {
  try {
    const { data } = await axios.get(
      `https://svabwkanvdan.site/api/promocode/getAll`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getPromocode = async (promocode) => {
  try {
    const { data } = await axios.get(
      `https://svabwkanvdan.site/api/promocode/get?promo=${promocode}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createPromocode = async (data) => {
  try {
    await axios.post(`https://svabwkanvdan.site/api/promocode/create`, data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updatePromocode = async (data) => {
  try {
    await axios.patch(`https://svabwkanvdan.site/api/promocode/update`, data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addToCart = async (_id, cart) => {
  try {
    const res = await axios.patch(`https://svabwkanvdan.site/api/cart/add`, {
      _id,
      cart,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deletePromocode = async (_id) => {
  try {
    await axios.delete(
      `https://svabwkanvdan.site/api/promocode/delete?_id=${_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getCurrentReview = async (_id) => {
  try {
    const { data } = await axios.get(
      `https://svabwkanvdan.site/api/review/current/${_id}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getReview = async (body) => {
  try {
    const { data } = await axios.post(
      `https://svabwkanvdan.site/api/review/get`,
      body
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllReviews = async () => {
  try {
    const { data } = await axios.get(
      `https://svabwkanvdan.site/api/review/getAll`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createReview = async (data) => {
  try {
    await axios.post(`https://svabwkanvdan.site/api/review/new`, data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateReview = async (data) => {
  try {
    await axios.patch(`https://svabwkanvdan.site/api/review/update`, data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const hideReview = async (_id, show) => {
  try {
    await axios.patch(`https://svabwkanvdan.site/api/review/hide`, {
      _id,
      show,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAllBlocks = async (show = false) => {
  try {
    if (show) {
      const { data } = await axios.get(
        `https://svabwkanvdan.site/api/block/all?show=${show}`
      );
      return data;
    } else {
      const { data } = await axios.get(
        `https://svabwkanvdan.site/api/block/all`
      );
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteBlock = async (_id) => {
  try {
    await axios.delete(`https://svabwkanvdan.site/api/block/delete/${_id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getOneBlock = async (_id) => {
  try {
    const { data } = await axios.get(
      `https://svabwkanvdan.site/api/block/current/${_id}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createBlock = async (blockData) => {
  try {
    const { data } = await axios.post(
      `https://svabwkanvdan.site/api/block/new`,
      blockData
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateBlock = async (_id, blockData) => {
  try {
    const { data } = await axios.patch(
      `https://svabwkanvdan.site/api/block/update/${_id}`,
      blockData
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const hideBlock = async (_id, show) => {
  try {
    const { data } = await axios.patch(
      `https://svabwkanvdan.site/api/block/hide/${_id}`,
      { show }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllFastfilters = async (show = false) => {
  try {
    if (show) {
      const { data } = await axios.get(
          `https://svabwkanvdan.site/api/fastfilter/all?show=${show}`
      );
      return data;
    } else {
      const { data } = await axios.get(
          `https://svabwkanvdan.site/api/fastfilter/all`
      );
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteFastfilter = async (_id) => {
  try {
    await axios.delete(`https://svabwkanvdan.site/api/fastfilter/delete/${_id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getOneFastfilter = async (_id) => {
  try {
    const { data } = await axios.get(
        `https://svabwkanvdan.site/api/fastfilter/current/${_id}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createFastfilter = async (fastfilterData) => {
  try {
    const { data } = await axios.post(
        `https://svabwkanvdan.site/api/fastfilter/new`,
        fastfilterData
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateFastfilter = async (_id, fastfilterData) => {
  try {
    const { data } = await axios.patch(
        `https://svabwkanvdan.site/api/fastfilter/update/${_id}`,
        fastfilterData
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const hideFastfilter = async (_id, show) => {
  try {
    const { data } = await axios.patch(
        `https://svabwkanvdan.site/api/fastfilter/hide/${_id}`,
        { show }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
